<script setup>
    import TopNav from './TopNavBar.vue';
    import BottomNav from './BottomNavBar.vue';
    import FooterComponent from './FooterComponent.vue';
</script>

<template>
    <TopNav :lightBg="false" />

    <div class="main-content">
        <div class="wrapper-gap">
            <div class="duckywhite top-message">are you struggling with</div>
            <div class="lead-messaging" id="scrolling-text-container">
                <div id="scrolling-text" class="scroll-text-wrapper duckywhite">
                    thinking there has to be a better way?
                </div>
            </div>
        </div>
        <div class="call-to-action">let's chat</div>
    </div>
    <BottomNav :scrollablePage="false" :lightBg="false" :leftArrow="lArrow" :rightArrow="rArrow"/>
    <FooterComponent/>
</template>
  
<script>
    export default {
        name: 'HomePage',
        components: {
            TopNav,
            BottomNav,
            FooterComponent,
        },
        created() {
            this.intervalId = setInterval(this.updateText, 5000);
        },
        mounted() {
            this.applyNavClasses();
        },
        data() {
            return {
                intervalId: null,
                rArrow: {
                    active: true,
                    displayName: "about",
                    routerName: "/about"
                },
                lArrow: {
                    active: false,
                    displayName: "",
                    routerName: ""
                },
            }
        },
        beforeUnmount() {
            clearInterval(this.intervalId);
        },
        methods: {
            applyNavClasses() {
                document.body.classList.remove("light");
                document.querySelector("#navbarmenu > a:nth-child(1) > div:nth-child(1)").classList.add("active-nav");
            },
            updateText() {
                let textElement = document.querySelector("#scrolling-text");
                this.fadeOut(textElement);

                setTimeout(() => {
                    let newText = this.getRandomElement();
                    while (newText == textElement.textContent) {
                        newText = this.getRandomElement();
                    }
                    textElement.textContent = newText;
                    this.fadeIn(textElement)
                }, 2000);

            },
            getRandomElement() {
                const scrollTextArr = [
                    "recurring data transfers?",
                    "data processing?",
                    "repetitive copy and paste?",
                    "thinking there has to be a better way?",
                    "questions about security?"
                ];
                const arrLen = scrollTextArr.length;
                const randomIndex = Math.floor(Math.random() * arrLen);
                return scrollTextArr[randomIndex];
            },
            fadeOut(ele) {
                ele.classList.remove('fade-in');
                ele.classList.add('fade-out');
            },
            fadeIn(ele) {
                ele.classList.remove('fade-out');
                ele.classList.add('fade-in');
            },
        },

    };
</script>

<style lang="scss">
/* 
  **********************************
    MAIN CONTENT
  **********************************
   */

.body-home {
    // background-image: url("../assets/../assets/img/Background_home.png");
    background-image: url("../assets/../assets/img/Background_home.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
}

.main-content {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7.5rem;
    margin-left: 9.25rem;
    margin-top: 8.75rem;
    height: 50vh;

    .top-message {
        font-family: "Palanquin", Helvetica;
        font-size: 3rem;
        font-weight: 200;
        line-height: 4rem;
    }
}

#scrolling-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    font-family: "Palanquin", Helvetica;
    font-weight: 700;
    font-size: 3rem;
    line-height: 4rem;
}

.call-to-action {
    font-family: "Cormorant", Helvetica;
    font-weight: 500;
    font-style: italic;
    color: var(--rubber-duckyyellow);
    font-size: 11.25rem;
    letter-spacing: 0;
    line-height: normal;
}


@media only screen and (min-device-width: 320px) and (orientation: portrait) {
    .main-content {
        margin: 2rem 1rem;
        gap: 2rem;

        .wrapper-gap {

            .top-message,
            #scrolling-text {
                font-size: 2.25rem;
                line-height: 3rem;
            }

            #scrolling-text {
                height: 10rem;
            }

        }

        .call-to-action {
            font-size: 5.625rem;
        }
    }
}
</style>