<script setup>
    import TopNav from './TopNavBar.vue';
    import BottomNav from './BottomNavBar.vue';
    import FooterComponent from './FooterComponent.vue';
</script>

<template>
    <TopNav :lightBg="true" :navLight="true"/>

    <div class="contact-content">
        <div class="title-container">
            <p class="title-text">
                <span class="title-lead">contact today to</span>
                <span class="title-trailing">get your project started</span>
            </p>
        </div>
        <div class="form-container">
            <form @submit.prevent="submitContact" id="contact-form" name="contact" class="contact-form" data-netlify="true" netlify-honeypot="bot-field">
                <p style="display: none;"><label>Don't fill this out if you're human: <input name="bot-field" /></label></p>
                <div class="form-group">
                    <label for="name" class="input-title">name*</label>
                    <input name="name" v-model="contactForm.name" class="input-field" placeholder="" type="text" required />
                </div>
                <div class="form-group">
                    <label for="email" class="input-title">email*</label>
                    <input name="email" class="input-field" placeholder="" type="email" v-model="contactForm.email"
                    pattern="[a-zA-Z0-9._\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}" required />
                </div>
                <div class="form-group">
                    <label for="phone" class="input-title">phone</label>
                    <input name="phone" class="input-field" placeholder="" type="tel" v-model="contactForm.phone" />
                </div>
                <div class="form-group">
                    <label for="company" class="input-title">company</label>
                    <input name="company" v-model="contactForm.company" class="input-field" type="text" placeholder="">
                </div>
                <div class="form-group">
                    <label for="message" class="input-title-long">project description</label>
                    <textarea name="message" v-model="contactForm.message" class="input-text-area input-field" type="text" placeholder=""></textarea>
                </div>
                <div class="btn-container">
                    <button type="submit" class="submit-button">
                        submit
                    </button>
                </div>
            </form>
        </div>
    </div>

    <BottomNav 
        :scrollablePage="false" 
        :lightBg="true" 
        :leftArrow="lArrow" 
        :rightArrow="rArrow"
    />
    <FooterComponent/>
</template>

<script>
export default {
    name: "ContactPage",
    components: {
        TopNav,
        FooterComponent,
    },
    mounted() {
        this.applyNavClasses();
    },
    data() {
        return {
            rArrow: {
                active: false,
                displayName: "",
                routerName: ""
            },
            lArrow: {
                active: true,
                displayName: "client journey",
                routerName: "/clientjourney"
            },
            contactForm: {
                name: "",
                email: "",
                phone: "",
                company: "",
                message: "",
            },
        }
    },
    methods: {
        applyNavClasses() {
            document.body.classList.add("light");
            document.querySelector("#navbarmenu > a:nth-child(5) > div:nth-child(1)").classList.add("active-nav");
            document.querySelector("footer > div").classList.add("ft-light");
            if (window.innerWidth >= 320 && window.innerWidth < 720) {
                document.querySelector('footer').classList.add('multipage');
            } else {
                document.querySelector('footer').classList.remove('multipage');
            }
        },
        encode(data) {
            return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join('&');
        },
        submitContact() {
            console.log(this.contactForm);
            fetch("/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: this.encode({
                    "form-name":"contact",
                    ...this.contactForm
                })
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    alert('Message sent! We will be in touch!')
                    this.$router.push('/contact');
                })
                .catch(error => {
                    // Handle error here
                    alert('There was a problem sending the email:', error);
                    this.$router.push('/contact');
                });
                this.resetForm();
        },
        resetForm() {
            for (let k in this.contactForm) {
                this.contactForm[k] = "";
            }
        }
    }
    
}
</script>

<style lang="scss">
.contact-content {
    background: var(--default-background-color);
    background-blend-mode: normal, color, overlay;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 5rem);

    .title-container {
        width: 50%;
        text-align: right;
        margin-right: 4rem;
    }

    .form-container {
        width: 50%;
    }
}

.title-text {
    font-family: "Palanquin", Helvetica;
    font-size: 2.75rem;
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    // margin-left: 10rem;


    .title-lead {
        font-weight: 400;
    }

    .title-trailing {
        font-weight: 700;
    }
}

#contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 38rem;
    gap: 1.875rem;
    position: absolute;
    top: 16.813rem;

    .form-group {
        display: flex;
        align-items: center;
        gap: 1.875rem;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
        position: relative;

        label {
            position: relative;
            font-family: "Palanquin", Helvetica;
            font-weight: 500;
            font-size: 1.5rem;
            text-align: right;
            color: var(--rubber-duckyblack);
            width: 15rem;

            .input-title {
                text-align: right;
            }

            .input-title-long {
                width: 13rem;
            }
        }


        .input-field {
            border: 0.1px solid var(--default-background-color);
            border-radius: 3px;
            flex: 1;
            height: 2rem;

        }

        .input-text-area {
            height: 8rem;
        }
    }

    .btn-container {
        width: 100%;
        text-align: center;
        margin-left: 3rem;
    }

    .submit-button {
        width: 10rem;
        height: 2.5rem;
        color: var(--rubber-duckyblack);
        font-family: "Palanquin Dark", Helvetica;
        font-weight: 400;
        background-color: var(--rubber-duckyorange);
        border: none;
        border-radius: 0.5rem;
        transition: background-color 0.3s ease;

        &:hover {
            cursor: pointer;
            color: var(--rubber-duckywhite);
            background-color: var(--rubber-duckyblack);
        }
    }
}

@media only screen and (min-device-width: 320px) and (orientation: portrait) {
    .contact-content {
        flex-direction: column;
        // height: 50vh;
        align-items: center;
        height: auto;
        margin-bottom: 4rem;

        .title-container,
        .form-container {
            width: 100%;
        }

        .title-container {
            margin: 0;
            text-align: center;

            .title-text {
                font-size: 2rem;
                margin-top: 2rem;
            }
        }

        .form-container {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }

    #contact-form {
        padding: 0 1.5rem;
        width: 100%;
        position: static;

        .form-group {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

            .input-title,
            .input-title-long {
                text-align: left;
            }

            .input-field {
                flex: auto;
                width: 100%;
                height: 3rem;
            }

            .input-text-area {
                height: 7rem !important;
            }
        }

        .btn-container {
            margin: 0;
            width: auto;
        }
    }
    

}</style>