import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import AboutPage from './components/AboutPage.vue';
import ServicesPage from './components/ServicesPage.vue';
import ClientJourneyPage from './components/ClientJourneyPage.vue';
import ContactPage from './components/ContactPage.vue';

const routes = [
  { path: '/', component: HomePage, name: 'HomePage' },
  { path: '/about', component: AboutPage, name: 'AboutPage' },
  { path: '/services', component: ServicesPage, name: 'ServicesPage' },
  { path: '/clientjourney', component: ClientJourneyPage, name: 'ClientJourneyPage' },
  { path: '/contact', component: ContactPage, name: 'ContactPage' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);

app.mount('#app');