import './styles.css';
<template>
    <div id="app">
        <router-view />
    </div>
</template>
  
<script>
export default {
    name: 'App',
};
</script>

<style lang="scss">
:root {
    --rubber-duckyyellow: rgba(255, 214, 42, 1);
    --rubber-duckyorange: rgba(255, 144, 0, 1);
    --rubber-duckywhite: rgba(255, 255, 255, 1);
    --rubber-duckyblack: rgba(45, 47, 53, 1);
    --rubber-duckydark-gray: rgb(83, 87, 101);
    --default-background: #2d2f35;
    --default-background-light: hsl(0, 0%, 89%);
    --default-dropshadow: 0px 15px 100px 0px rgba(45, 47, 53, 0.1);
    --orange-dropshadow: 0px 15px 100px 0px rgba(255, 144, 0, 0.2);
    --rubber-duckyorange-secondary: rgb(247, 176, 70);
    background-color: var(--default-background);
}

body {
    margin: 0;
    padding: 0;
}

// **********************************
// FONTS
// **********************************

.pal-font {
    font-family: "Palanquin", Helvetica;
    color: var(--rubber-duckyblack);

    &-400 {
        font-weight: 400;
    }

    &-500 {
        font-weight: 500;
    }

    &-700 {
        font-weight: 700;
    }

    &-dark {
        font-family: "Palanquin Dark", Helvetica;
    }
}

.corm-font {
    font-family: "Cormorant", Helvetica;
    color: var(--rubber-duckyblack);

    &-400 {
        font-weight: 400;
    }

    &-500 {
        font-weight: 500;
    }

    &-700 {
        font-weight: 700;
    }
}

// **********************************
// COLORS
// **********************************

.duckywhite {
    color: var(--rubber-duckywhite)
}

.duckyyellow {
    color: var(--rubber-duckyyellow)
}

.duckyorange {
    color: var(--rubber-duckyorange)
}

.duckyblack {
    color: var(--rubber-duckyblack)
}

.duckydarkgrey {
    color: var(--rubber-duckydark-gray)
}

// **********************************
// LOGOS
// **********************************

.ducky-text-logo {
    justify-content: center;
    width: fit-content;
    font-family: "Palanquin Dark", sans-serif;
    font-weight: 400;
    color: var(--rubber-duckywhite);
    font-size: 1.25rem;
    text-align: center;

    span:nth-child(2) {
        font-family: "Cormorant", Helvetica;
        font-style: italic;
        font-size: 1.5rem;
    }
}

.ducky-text-logo-light {
    justify-content: center;
    width: fit-content;
    font-family: "Palanquin Dark", sans-serif;
    font-weight: 400;
    color: var(--rubber-duckyblack);
    font-size: 1.25rem;
    text-align: center;
    line-height: normal;

    span:nth-child(2) {
        font-family: "Cormorant", Helvetica;
        font-style: italic;
        font-size: 1.5rem;
    }
}

a {
    text-decoration: none;
    color: inherit;

    :hover {
        cursor: pointer;
    }
}

// **********************************
// MISC PROPS
// **********************************
.light {
    background-color: var(--default-background-light);
}

.active-nav {
    font-weight: bolder !important;
}

.scroll-frame {
    display: flex;
    position: absolute;
    bottom: 3%;
    left: 44%;
    gap: 1rem;
    opacity: 0.5;
    justify-content: center;
    animation: pulse 5s 2s infinite;


    .scroll-text {
        width: fit-content;
        font-family: "Palanquin Dark", Helvetica;
        font-weight: 400;
        color: var(--rubber-duckywhite);
        font-size: 1.125rem;

        &.text-light {
            color: var(--rubber-duckydark-gray);
        }
    }

    img {
        margin-right: 7rem;
        opacity: 0.5;
    }

}

/* 
  **********************************
    Animation Styles 
  **********************************
*/

.fade-in {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

</style>