<script setup>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faBars } from '@fortawesome/free-solid-svg-icons';
</script>

<template>
    <nav class="top-nav-organism" :class="{ 'white': lightBg, 'nav-light': navLight }">
        <div class="logo">
            <a href="/"><img class="icon" src="../assets/img/Icon.png" /></a>
            <p class="ducky-text-logo duckywhite corm-font corm-font-500">
                <a href="/">
                    <span>rubber ducky</span>
                    <span> solutions</span>
                </a>
            </p>
        </div>
        <div class="navbar">
            <div class="navoverlay">
                <a role="button" class="navbar-mobile" aria-label="menu" aria-expanded="false" data-target="navbarmenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
                <a class="close-mobile-nav" @click="closeNavMobile()">&times;</a>
                <div id="navbarmenu">
                    <a @click="navToPage('HomePage')" class="mobile-only">
                        <div class="navbar-wrapper">home</div>
                    </a>
                    <a @click="navToPage('AboutPage')">
                        <div class="navbar-wrapper">about me</div>
                    </a>
                    <a @click="navToPage('ServicesPage')">
                        <div class="navbar-wrapper">my services</div>
                    </a>
                    <a @click="navToPage('ClientJourneyPage')">
                        <div class="navbar-wrapper">client journey</div>
                    </a>
                    <a @click="navToPage('ContactPage')">
                        <div class="navbar-wrapper">let's chat</div>
                    </a>
                </div>
            </div>
            <a class="hb-icon" @click="showNavMobile()">
                <!-- <i class="fa fa-bars"></i> -->
                <FontAwesomeIcon :icon="faBars" />
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    name: "TopNav",
    props: {
        lightBg: Boolean,
        navLight: Boolean,
    },
    methods: {
        navToPage(pageName) {
            this.$router.push({ name: pageName });
        },
        showNavMobile() {
            document.querySelector(".navoverlay").style.width = "100%";
        },
        closeNavMobile() {
            document.querySelector(".navoverlay").style.width = "0%";
        }
    }
}

</script>

<style lang="scss">
/* 
  **********************************
    TOP NAVBAR 
  **********************************
*/

.top-nav-organism {
    // width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;

    .logo {
        display: inline-flex;
        align-items: center;
        gap: 0.75rem;
        flex: 0 0 auto;
    }

    .navbar {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .close-mobile-nav {
            display: none;
        }

        #navbarmenu {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3.75rem;

            .mobile-only {
                display: none;
            }

            a {
                position: relative;
                text-decoration: none;

                ::after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: var(--rubber-duckyorange);
                    transition: width 0.3s;
                }

                :hover::after {
                    width: 100%;
                }
            }
        }

        .hb-icon {
            color: var(--rubber-duckywhite);
            font-size: 1.75rem;
            font-weight: 100;
            display: none;
        }
    }

    &.nav-light {
        background-color: var(--rubber-duckywhite);

        .logo .ducky-text-logo,
        .navbar-wrapper,
        .hb-icon {
            color: var(--rubber-duckyblack);
        }

        &.white {
            background-color: var(--default-background-light);
        }
    }
}

.navbar-wrapper {
    width: fit-content;
    font-family: "Palanquin", Helvetica;
    font-weight: 400;
    color: var(--rubber-duckywhite);
    font-size: 1.125rem;
}

@media only screen and (min-device-width: 320px) and (orientation: portrait) {

    .top-nav-organism {
        // overflow: hidden;

        .navbar {
            .navoverlay {
                height: 100%;
                width: 0;
                position: fixed;
                z-index: 1;
                right: 0;
                top: 0;
                background-color: var(--default-background);
                overflow-x: hidden;
                transition: 0.5s;

                #navbarmenu {
                    position: relative;
                    top: 5%;
                    text-align: center;
                    flex-direction: column;
                    gap: 1rem;

                    .mobile-only {
                        display: block;
                    }

                    a {
                        text-decoration: none;
                        font-size: 2rem;
                        color: var(--rubber-duckywhite);
                        display: block;
                        transition: 0.3s;

                        .navbar-wrapper {
                            font-family: "Cormorant", Helvetica;
                            font-style: italic;
                            font-size: 2rem;
                            font-weight: 400;
                            color: var(--rubber-duckywhite);

                            &.active-nav {
                                color: var(--rubber-duckyorange);
                            }
                        }

                        &:not(:last-child)::after {
                            content: "";
                            position: relative;
                            height: 50px;
                            top: 0.75rem;
                            background-color: var(--rubber-duckywhite);
                            width: 1px;
                            display: inline-block;
                            z-index: -10;
                        }
                    }


                }

                .close-mobile-nav {
                    display: block;
                    position: absolute;
                    right: 1rem;
                    top: -0.2rem;
                    font-size: 4rem;
                    text-decoration: none;
                    color: var(--rubber-duckywhite);
                }


            }

            .hb-icon {
                display: block;
            }
        }
    }
}
</style>