<script setup>
    import TopNav from './TopNavBar.vue';
    import BottomNav from './BottomNavBar.vue';
    import FooterComponent from './FooterComponent.vue';
</script>

<template>
    <TopNav/>

    <div class="element-services">
        <div class="services-content">
            <div class="top-content">
                <p class="title">
                    <span class="title-lead">working to serve </span>
                    <span class="title-trailing">you</span>
                </p>
                <p class="description">
                    My goal is to work with what you have! Whether that is an Excel workbook or a full-fledged server—I
                    would rather integrate your current tools over selling your something new.
                </p>
            </div>
            <div class="services-block">
                <div class="services">
                    <div class="service">
                        <div class="service-content">
                            <div class="icon">[=]</div>
                            <div class="service-text">
                                <div class="service-title">bridging software</div>
                                <p class="service-description">Software that integrates your current tools.</p>
                            </div>
                        </div>
                    </div>
                    <div class="service">
                        <div class="service-content">
                            <div class="icon">/+</div>
                            <div class="service-text">
                                <div class="service-title">improving process</div>
                                <p class="service-description">Finding ways to make things more efficient</p>
                            </div>
                        </div>
                    </div>
                    <div class="service">
                        <div class="service-content">
                            <div class="icon">{_}</div>
                            <div class="service-text">
                                <div class="service-title">security consulting</div>
                                <p class="service-description">Questions about IT security and policy setup</p>
                            </div>
                        </div>
                    </div>
                    <div class="service">
                        <div class="service-content">
                            <div class="icon">...)</div>
                            <div class="service-text">
                                <div class="service-title">general tech help</div>
                                <p class="service-description">Helping bridge the gap between you and your technology
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <BottomNav 
        :scrollablePage="false" 
        :lightBg="false" 
        :leftArrow="lArrow" 
        :rightArrow="rArrow"
    />
    <FooterComponent/>
</template>

<script>
export default {
    name: "ServicesPage",
    components: {
        TopNav,
        BottomNav,
        FooterComponent,
    },
    mounted() {
        this.applyNavClasses();
    },
    data() {
        return {
            rArrow: {
                active: true,
                displayName: "client journey",
                routerName: "/clientjourney"
            },
            lArrow: {
                active: true,
                displayName: "about",
                routerName: "/about"
            }
        }
    },
    methods: {
        applyNavClasses() {
            document.body.classList.remove("light", "body-contact");
            document.querySelector("#navbarmenu > a:nth-child(3) > div:nth-child(1)").classList.add("active-nav");
            if (window.innerWidth >= 320 && window.innerWidth < 720) {
                document.querySelector('footer').classList.add('multipage');
            } else {
                document.querySelector('footer').classList.remove('multipage');
            }
        },
    }
}
</script>

<style lang="scss">
.body-services {
    background-image: url("../assets/../assets/img/Background_services.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
}

.element-services {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .services-content {
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }
}

.top-content {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    margin-top: 5rem;

    .title {
        font-family: "Palanquin", Helvetica;
        font-weight: 200;
        color: var(--rubber-duckywhite);
        font-size: 3rem;
        margin: 0;

        .title-trailing {
            font-weight: 700;
        }
    }

    .description {
        font-family: "Cormorant", Helvetica;
        font-weight: 400;
        color: var(--rubber-duckywhite);
        font-size: 1.5rem;
        width: 50%;
        text-align: center;
        margin: 0;
    }
}

.services-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    width: 100%;

    .services {
        display: flex;
        align-items: center;
        gap: 2.5rem;
        align-self: stretch;
        width: 100%;

        .service {
            flex: 1;
            flex-grow: 1;
            background-color: var(--rubber-duckydark-gray);
            box-shadow: var(--default-dropshadow);
            width: 14rem;
            height: 14rem;
            border-radius: 0.625rem;

            .service-content {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;

                .icon {
                    font-family: "Palanquin", Helvetica;
                    font-weight: 700;
                    font-size: 4rem;
                    color: var(--rubber-duckyyellow);
                    text-align: center;
                    position: relative;
                    left: -1.75rem;
                    top: -2rem;
                }

                .service-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    flex: 1;
                    flex-grow: 1;

                    .service-title {
                        align-self: stretch;
                        font-family: "Palanquin", Helvetica;
                        font-weight: 500;
                        color: var(--rubber-duckywhite);
                        font-size: 1.5rem;
                    }

                    .service-description {
                        align-self: stretch;
                        font-family: "Cormorant", Helvetica;
                        font-weight: 400;
                        color: var(--rubber-duckywhite);
                        font-size: 1.5rem;
                        width: 90%;
                    }
                }
            }
        }
    }
}


@media only screen and (min-device-width: 320px) and (orientation: portrait) {
    .body-services {
        height: auto;
    }

    .element-services {
        flex-direction: column;
        width: auto;


        .services-content {

            .top-content {
                align-items: flex-start;
                margin-top: 0;
                padding: 0 1rem;
                gap: 1rem;

                .title {
                    font-size: 2.5rem;
                }

                .description {
                    text-align: left;
                    width: auto;
                }
            }

            .services-block {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .services {
                    display: flex;
                    flex-direction: column;
                    width: auto;
                    align-items: center;
                    gap: 1.5rem;
                    margin-bottom: 4rem;

                    .service-content {

                        .icon {
                            font-size: 3rem;
                        }

                        .service-title,
                        .service-description {
                            font-size: 1.2rem;
                        }

                    }

                    .service {
                        width: 16rem;
                    }

                }
            }
        }
    }
}
</style>