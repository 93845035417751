<template>
    <div class="bottom-nav-animated" :class="{'multipage' : scrollablePage, 'right-only' : !leftArrow.active}">
        <router-link :to="leftArrow.routerName" v-show="leftArrow.active" class="nav-left">

            <a>
                <div class="bottom-nav-text">
                    <div :class="{ 'duckyblack' : lightBg}" id="bottom-nav">{{ leftArrow.active ? leftArrow.displayName.toLowerCase() : "" }}</div>
                </div>
                <img class="arrow left-arrow" src="../assets/img/arrow_left.svg" />
            </a>
        </router-link>
        <router-link :to="rightArrow.routerName" v-show="rightArrow.active" class="nav-right">

            <a>
                <div class="bottom-nav-text">
                    <div :class="{ 'duckyblack' : lightBg}" id="bottom-nav">{{ rightArrow.active ? rightArrow.displayName.toLowerCase() : ""}}</div>
                </div>
                <img class="arrow right-arrow" src="../assets/img/arrow_right.svg" />
            </a>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "BottomNav",
    props: {
        scrollablePage: Boolean,
        lightBg: Boolean,
        leftArrow: {
            active: Boolean,
            displayName: String,
            routerName: String,
        },
        rightArrow: {
            active: Boolean,
            displayName: String,
            routerName: String,
        }
    },
    mounted() {
        document.addEventListener("keydown", this.handleKeyDownEvents);
    },
    unmounted() {
        document.removeEventListener('keydown', this.handleKeyDownEvents);
    },
    methods: {
        handleKeyDownEvents(event) {
            switch (event.key) {
                case "ArrowRight":
                    this.$router.push(this.rightArrow.routerName)
                    break;
                case "ArrowLeft":
                    this.$router.push(this.leftArrow.routerName)
                    break;
                default:
                    break;
            }
        }
    }
}

</script>

<style lang="scss">
/*
**********************************
    BOTTOM NAV BAR 
**********************************
*/

.bottom-nav-animated {
    position: fixed;
    bottom: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.right-only {
        justify-content: flex-end !important;
    }

    a {
        text-decoration: none;
        
        &.nav-left {
            margin-left: 1.25rem;
        }

        &.nav-right {
            margin-right: 1rem;
        }

        &:hover .left-arrow {
            transform-origin: left center;
            transform: translateX(-20px);
        }

        &:hover .right-arrow {
            transform-origin: right center;
            transform: translateX(20px);
        }
    }

}

.bottom-nav-text {
    font-family: "Cormorant", Helvetica;
    font-weight: 400;
    font-style: italic;
    color: var(--rubber-duckywhite);
    font-size: 1.875rem;

}

.arrow {
    transition: transform 0.5s ease;
}

@media only screen and (min-device-width: 320px) and (orientation: portrait) {


    .bottom-nav-animated {
        display: none;
    }

}
</style>