<script setup>
    import TopNav from './TopNavBar.vue';
    import BottomNav from './BottomNavBar.vue';
    import FooterComponent from './FooterComponent.vue';
</script>

<template>
    <TopNav/>

    <div class="client-journey-main-page">
        <div class="title-container">
            <div class="page-title corm-font corm-font-500">client journey</div>
            <div class="scroll-frame">
                <div class="scroll-text">scroll</div>
                <img src="../assets/img/scroll_indicator.svg" alt="scroll">
            </div>
        </div>


    </div>
    
    <div class="client-journey-content">
        <div class="client-journey-cards">
            <div class="step-card">
                <div class="description-container pal-font pal-font-700">
                    <p class="">
                        <span class="step-header-lead">let's talk!</span> i'm not
                        a quack :)
                    </p>
                    <p class="description corm-font corm-font-400 corm-font corm-font-400">
                        The best way for me to learn about your organization, goals, and pain points
                        is to hear from
                        you. To start we will set up a brief, 60 minute meeting to get to know each
                        other and to learn
                        about what type of technology challenges are barriers to doing your work or
                        doing it well.
                        Consider this session “coffee on me” to get to know one another -- let's see
                        if we're a good
                        fit.
                    </p>
                </div>
                <div class="step-title title-right corm-font corm-font-500">
                    <div class="step-title-text">step</div>
                    <div class="step-title-text">one</div>
                </div>

            </div>
            <div class="step-card">
                <div class="step-title title-left corm-font corm-font-500">
                    <div class="step-title-text">step</div>
                    <div class="step-title-text">two</div>
                </div>
                <div class="description-container desc-right  pal-font pal-font-700">
                    <p><span>map the possibilities</span></p>
                    <p class="description corm-font corm-font-400">
                        Let's lay out the possibilities. This will take a few collaboration sessions
                        -- the better we do
                        planning here the easier and faster the work will be later. Together we will
                        work through the
                        possible solutions to your technology barriers and how we can streamline
                        processes. This is also
                        the time to prioritize our quick wins as well as solutions that may take
                        more time.
                    </p>
                </div>
            </div>
            <div class="step-card">
                <div class="description-container pal-font pal-font-700">
                    <p>pick the path</p>
                    <p class="description corm-font corm-font-400">
                        Depending on your project and the individual needs your organization may
                        have, I will put
                        together a couple options of work - projects with their timelines and cost.
                        You get to pick how
                        we will proceed and then we can get formal agreements. Then it's time to set
                        me loose to work on
                        your project.<br /><br />We'll conduct regular check-ins to ensure the
                        smooth and efficient
                        management of the project. These meetings will help us stay aligned, address
                        any issues
                        promptly, and maintain a high level of collaboration throughout the
                        project's lifecycle.
                    </p>
                </div>
                <div class="step-title title-right corm-font corm-font-500">
                    <div class="step-title-text">step</div>
                    <div class="step-title-text">three</div>
                </div>
            </div>
            <div class="step-card">
                <div class="step-title title-left corm-font corm-font-500">
                    <div class="step-title-text">step</div>
                    <div class="step-title-text">four</div>
                </div>
                <div class="description-container desc-right pal-font pal-font-700">
                    <p>showcase and train</p>
                    <p class="description corm-font corm-font-400">
                        Once I've got all our ducks in a line, we will take some time to walk
                        through the solutions we
                        have created. If it involves training, I will make sure to personally train
                        you and provide
                        documentation so you are never without resources.
                    </p>
                </div>
            </div>
            <div class="step-card">
                <div class="outro pal-font pal-font-400">
                    <p>
                        ...and <span class="pal-font-700">beyond</span>!
                    </p>
                    <p class="description-outro corm-font corm-font-400">
                        So, we've wrapped up our projects together… now what? Our contract will include
                        details if you need
                        support on your solutions. Rest assured, I've got you covered!
                    </p>
                </div>
            </div>
        </div>
        <div class="testimonials" style="display: none;">
            <div class="testimonial-container">
                <div class="quote-mark q-left corm-font corm-font-700">“</div>
                <div class="testimonial-cards">
                    <p class="testimonials-title pal-font pal-font-400">
                        see what others <span class="pal-font-700">are saying</span>
                    </p>
                    <div class="testimonial">
                        <div class="testimonial-text">
                            <p class="testimonial-quote corm-font corm-font-400">
                                <!-- As a business owner, I can&#39;t express how grateful I am for the incredible backend development services we received from [Fake Company Name]. Their team of coding wizards
                                    turned our ambitious ideas into a seamless, lightning-fast reality. Our data is now as secure as
                                    Fort Knox, and our website runs smoother than a hot knife through butter. I&#39;m convinced
                                    they must have some secret backend development sauce because the results are nothing short of
                                    magical. Thank you, Rubber Ducky Solutions, for transforming our digital dreams into a
                                    cybernetic wonderland! -->
                                    <!-- {{ currentTestimonial.quote }} -->
                            </p>
                            <div class="testimonial-author pal-font-dark pal-font-400">
                                <!-- john doe -->
                                <!-- {{ currentTestimonial.author }} -->
                                <!-- <p class="author-company">CEO of Imaginary Ventures Inc.</p> -->
                                <!-- <p class="author-company">{{ currentTestimonial.authorTitle }} at {{ currentTestimonial.company }}</p> -->
                            </div>
                            <div class="testimonial-nav pal-font-dark pal-font-400">
                                <div class="previous">
                                    <img class="arrow-prev" src="../assets/img/arrow_left_test.svg" />
                                    prev
                                </div>
                                <div class="next">
                                    next
                                    <img class="arrow-next" src="../assets/img/arrow_right_test.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="quote-mark q-right corm-font corm-font-700">”</div>
            </div>

        </div>
    </div>
    <BottomNav 
        :scrollablePage="true" 
        :lightBg="false" 
        :leftArrow="lArrow" 
        :rightArrow="rArrow"
    />
    <FooterComponent :stickyFooter="true"/>
</template>

<script>
// import testimonials from '../data/testimonials.json';

export default {
    name: "ClientJourneyPage",
    components: {
        TopNav,
        BottomNav,
        FooterComponent,
    },
    created() {
        window.addEventListener('scroll', this.fadeBottomNav)
    },
    mounted() {
        this.applyNavClasses();
        // this.showHideTestimonials();
        // console.log(testimonials);
        // this.keys = Object.keys(this.items);
    },
    unmounted() {
        window.removeEventListener('scroll', this.fadeBottomNav)
    },
    data() {
        return {
            rArrow: {
                active: true,
                displayName: "let's chat",
                routerName: "/contact"
            },
            lArrow: {
                active: true,
                displayName: "my services",
                routerName: "/services"
            },
            // items: testimonials,
            // keys: [],
            // currInd: 0,
        }
    },
    // computed: {
    //     currentTestimonial() {
    //         const currItemKey = this.keys[this.currInd];
    //         return this.items[currItemKey];
    //     },
    // },
    methods: {
        applyNavClasses() {
            document.body.classList.remove("light", "body-contact");
            document.querySelector("#navbarmenu > a:nth-child(4) > div:nth-child(1)").classList.add("active-nav");
        },
        fadeBottomNav() {
            if (window.scrollY > 10) {
                document.querySelector('.bottom-nav-animated').classList.remove('fade-in');
                document.querySelector('.bottom-nav-animated').classList.add('fade-out');
            } else {
                document.querySelector('.bottom-nav-animated').classList.add('fade-in');
                document.querySelector('.bottom-nav-animated').classList.remove('fade-out');
            }
        },
        // nextTestimonial() {
        //     this.currentTestimonial = (this.currInd + 1) % this.keys.length;
        // },
        // prevTestimonial() {
        //     this.currentTestimonial = (this.currInd - 1) % this.keys.length;
        // }
    }
}
</script>

<style lang="scss">
@mixin step-card-background-props {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: -1;
    border-radius: 20px;
}

.client-journey-main-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // height: 100vh;
    margin: 10rem 0 30rem 0;


    .title-container {
        width: 100%;

        .page-title {
            font-style: italic;
            color: var(--rubber-duckyyellow);
            font-size: 11.25rem;
            text-align: center;
            position: relative;
            top: 25%;
        }
    }
}


.step-title {
    // width: 100%;
    font-style: italic;
    color: var(--rubber-duckyyellow) !important;
    font-size: 11.25rem;
    line-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.title-right {
        align-items: flex-end;
    }

    &.title-left {
        align-items: flex-start;
    }
}

.client-journey-main-page::after {
    position: absolute;
    content: '';
    left: 50%;
    top: 65%;
    width: 1px;
    height: 100vh * 3.8;
    background-color: var(--rubber-duckyyellow);
    z-index: -10;
}

.step-card {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: var(--default-dropshadow);

    &:nth-of-type(1)::before {
        @include step-card-background-props;
        background-image: url("../assets/../assets/img/Background-1.png");
    }

    &:nth-of-type(2)::before {
        @include step-card-background-props;
        background-image: url("../assets/../assets/img/Background-2.png");
    }

    &:nth-of-type(3)::before {
        @include step-card-background-props;
        background-image: url("../assets/../assets/img/Background-3.png");
        height: 127%;
        background-position: center top;
    }

    &:nth-of-type(4)::before {
        @include step-card-background-props;
        background-image: url("../assets/../assets/img/Background-4.png");
    }
}

.client-journey-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 100%;

    .client-journey-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10rem;
        gap: 10rem;

        &:first-child {
            margin-top: 5rem;
        }
    }

    .step-card {
        display: flex;
        flex-direction: row;
        width: calc(100vw - 40rem);
        height: 40rem;
        justify-content: center;
        align-items: center;

        &:last-child {
            align-items: normal;
        }

        .description-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                width: fit-content;
                color: var(--rubber-duckyorange) !important;
                font-size: 1.2rem;
                margin: 0;

                .step-header-lead {
                    font-weight: 700;
                }
            }

            .description {
                width: 42.25rem;
                color: var(--rubber-duckywhite) !important;
                font-size: 1.5rem;

            }
        }

        .outro {
            p {
                color: var(--rubber-duckywhite);
                font-size: 2.5rem;
                text-align: center;

                &.description-outro {
                    width: 42.25rem;
                    color: var(--rubber-duckywhite);
                    font-size: 1.5rem;
                    text-align: center;
                }
            }
        }

    }

}

.testimonials {
    align-items: center;
    background: linear-gradient(to bottom,
            var(--rubber-duckyorange), 70%, var(--rubber-duckyorange-secondary));
    overflow-y: hidden;

    .testimonial-container {
        display: flex;
        flex-direction: row;
        margin: 15rem 0;

        .testimonials-title {
            font-size: 3rem;
            text-align: center;
            color: var(--rubber-duckyblack);
            margin: 0;

        }

        .testimonial-cards {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 1.5rem;

            .testimonial {
                background-color: var(--rubber-duckywhite);
                border-radius: 10px;
                align-self: center;
                width: 60%;

                .testimonial-text {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;

                    .testimonial-quote {
                        flex: 1;
                        width: 70%;
                        color: var(--rubber-duckyblack);
                        font-size: 1.5rem;
                        margin-top: 4rem;
                    }

                    .testimonial-author {
                        width: 70%;
                        font-weight: 400;
                        color: var(--rubber-duckyblack);
                        font-size: 3rem;

                        .author-company {
                            font-size: 1.5rem;
                            margin: 0;
                        }
                    }
                }

                .testimonial-nav {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    color: var(--rubber-duckyblack);
                    font-size: 1.1rem;
                    text-align: center;
                    width: 100%;

                    div {
                        img {
                            position: relative;
                            color: var(--rubber-duckyorange-secondary);
                            mix-blend-mode: multiply;

                            // z-index: 2;
                            &.arrow-prev {
                                left: -2rem;
                            }

                            &.arrow-next {
                                left: 2rem;
                            }
                        }

                    }

                }
            }
        }

    }
}

.quote-mark {
    position: relative;
    mix-blend-mode: multiply;
    color: var(--rubber-duckyorange-secondary) !important;
    font-size: 18rem;
    top: 15rem;

    &.q-left {
        left: 18.5rem;
    }

    &.q-right {
        right: 18.5rem
    }
}


@media only screen and (min-device-width: 320px) and (orientation: portrait) {

    .client-journey-main-page {
        margin: 5rem 0 4rem 0;

        .title-container {
            .page-title {
                font-size: 5.625rem;
                line-height: 4rem;
            }
        }

        &::after {
            top: 70%;
            width: 1px;
            height: 100vh * 3.8;
            opacity: 0;
        }

    }

    .client-journey-cards {
        gap: 0 !important;

        &:first-child {
            margin: 0;
        }

        .step-card {
            position: relative;
            justify-content: center;
            width: 90vw !important;
            gap: 1rem;

            .step-title {
                align-items: center;
                font-size: 5.625rem;
            }

            .description-container {
                font-size: 1.25rem;
                line-height: 24px;
                padding: 0 1rem;
                gap: 1rem;

                .description {
                    width: fit-content !important;
                }
            }

            .step-title {
                flex-direction: row;
                gap: 1rem;
                text-align: center;
                line-height: normal;
                position: relative;
            }

            &:not(:last-child):nth-child(odd) {
                flex-direction: column-reverse;
            }

            &:not(:last-child):nth-child(even) {
                flex-direction: column;
            }

            &:nth-child(3) {
                height: 45rem;
            }

            @for $i from 1 through 4 {
                &:nth-of-type(#{$i})::before {
                    background-size: auto auto;

                    @if $i ==2 {
                        height: 32rem;
                    }

                    @else if $i ==3 {
                        background-position: center center;
                        height: 50rem;
                    }

                    @else if $i ==4 {
                        height: 25rem;
                    }

                    @else {
                        height: 30rem;
                    }

                }
            }

            &:first-child,
            &:nth-child(4) {
                height: 30rem;
            }
            
            .outro > p.description-outro {
                width: 20rem;
            }
        }
    }


    .testimonials {
        padding: 0;

        .testimonial-container {
            margin: 0;

            .quote-mark {
                display: none;
            }

            .testimonial-cards {
                height: 55rem;

                .testimonials-title {
                    font-size: 2rem;
                    line-height: 3rem;
                    display: flex;
                    flex-direction: column;
                }

                .testimonial {
                    width: 90%;

                    .testimonial-text {

                        .testimonial-quote {
                            font-size: 1.2rem;
                            width: 90%;
                            margin-top: 2rem;
                        }

                        .testimonial-author {
                            font-size: 2rem;
                            width: 90%;

                            .author-company {
                                font-size: 1.2rem;
                            }
                        }

                        .testimonial-nav {
                            justify-content: center;
                            gap: 3rem;
                            position: relative;
                            bottom: -3.2rem;
                        }
                    }
                }
            }
        }
    }

    .scroll-frame {
        display: none !important;
    }
}
</style>