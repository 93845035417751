<template>
<footer :class="{ 'multipage' : stickyFooter }">
        <div class="footer-text">@2024 Rubber Ducky Solutions</div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    props: {
        stickyFooter: Boolean
    }
}
</script>

<style lang="scss">
  /* FOOTER */
  footer {
      position: fixed;
    //   height: 2rem;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-family: "Cormorant", Helvetica;
      font-weight: 400;
      color: var(--rubber-duckywhite);
      white-space: nowrap;
      margin-bottom: 0.5rem;

      .footer-text {
          &.ft-light {
              color: var(--rubber-duckyblack);
          }
      }

      &.multipage {
          position: relative !important;
          margin-top: -2rem;
      }
  }
</style>