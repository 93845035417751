<script setup>
    import TopNav from './TopNavBar.vue';
    import BottomNav from './BottomNavBar.vue';
    import FooterComponent from './FooterComponent.vue';
</script>

<template>
    <TopNav :lightBg="false" :navLight="true"/>

    <div class="element-about">
        <div class="about-intro">
            <div class="intro-text">
                <div class="text-container pal-font pal-font-400">

                    <p class="header">
                        my passion is <span class="pal-font pal-font-700">learning</span>
                    </p>
                    <p class="intro-description corm-font">
                        My educational background includes a B.A. from Concordia College, Moorhead in International
                        Business with minors in Biology and Language. I love solving complicated problems and took those
                        skills to IBM post grad. This is where I discovered my love of coding. Automation is an
                        incredible tool to streamline work, build efficient processes, and remove barriers to doing the
                        core work well.
                    </p>
                </div>
            </div>
            <img class="" src="../assets/img/logan.png" />
            <div class="scroll-frame">
                <div class="scroll-text text-light">scroll</div>
                <img src="../assets/img/scroll_indicator_dark.svg" alt="scroll">
            </div>
        </div>
        <div class="about-tools">
            <div class="tools-container">
                <div class="text-container pal-font pal-font-400">
                    <p class="header">
                        the <span class="pal-font pal-font-700">right tools</span> for the job
                    </p>
                    <p class="tools-description corm-font corm-font-400">
                        I've been coding in multiple languages for over five years in the corporate space and
                        I'm interested in supporting communities in a more intimate way. All this to say, we have
                        options when picking the right tool for the job. Small businesses and nonprofits make our
                        communities great and may be missing opportunities to streamline processes due to budget
                        restraints.
                        These are the issues I like to solve!
                    </p>
                </div>
                <div class="symbol corm-font corm-font-500">(...)</div>
            </div>
        </div>
        <div class="about-skillset">
            <div class="skill-title-frame corm-font corm-font-500">
                <div class="skill-title">my skillset</div>
            </div>
            <div class="skills pal-font pal-font-700">
                <div class="skill">
                    <div class="icon">#( )</div>
                    <div class="skill-text">python</div>
                </div>
                <div class="skill">
                    <div class="icon">{=}</div>
                    <div class="skill-text">excel macros</div>
                </div>
                <div class="skill">
                    <div class="icon">://</div>
                    <div class="skill-text">web development</div>
                </div>
                <div class="skill">
                    <div class="icon">&gt;&gt;&gt;</div>
                    <div class="skill-text">cloud computing</div>
                </div>
                <div class="skill">
                    <div class="icon icon-expand">...</div>
                    <div class="skill-text">and much more</div>
                </div>
            </div>
        </div>
        <div class="about-rubberducky">
            <div class="content-block-logo">
                <img src="../assets/img/Icon.svg" />
                <div class="ducky-content">
                    <p class="header pal-font pal-font-400">
                        about the <span class="pal-font-700">“rubber ducky”</span>. . .
                    </p>
                    <p class="ducky-description corm-font corm-font-400">
                        Rubber ducky debugging is a well known practice in the software development world. The idea is
                        to have a rubber ducky (or many) on your desk to explain the problem to and viola! Talking
                        through the problem helps you solve it. Rubber ducky's are a safe, non-judgemental space to work
                        through challenges. Kind of like working with me!<br /><br />With a non-traditional educational
                        background for development, I have worked in small businesses in retail sales as well as had the
                        opportunity to learn and work with many different organizations including Cradle 2 Career MN and
                        Utepils Brewing.
                    </p>
                </div>
            </div>
        </div>
        <div class="about-hobbies">
            <div class="hobbies-title pal-font pal-font-400">
                <p>
                    when i'm <span class="pal-font-dark">not working</span>
                </p>
                <p class="hobbies-description corm-font corm-font-400">
                    I've been coding in multiple languages for over five years in the corporate space and I'm
                    interested in supporting communities in a more intimate way.
                </p>
            </div>
            <div class="hobbies-icons">
                <div class="icon-frame">
                    <img src="../assets/img/Biking.svg" />
                    <div class="pal-font pal-font-500">...I'm biking</div>
                </div>
                <div class="icon-frame">
                    <img src="../assets/img/Hiking.svg" />
                    <div class="pal-font pal-font-500">...or hiking</div>
                </div>
                <div class="icon-frame">
                    <img class="img" src="../assets/img/Skiing.svg" />
                    <div class="pal-font pal-font-500">...or skiing</div>
                </div>
                <div class="icon-frame">
                    <img class="img" src="../assets/img/Outdoors.svg" />
                    <div class="pal-font pal-font-500">...or just being outside!</div>
                </div>
            </div>
        </div>
    </div>
    <BottomNav 
        :scrollablePage="true" 
        :lightBg="true" 
        :leftArrow="lArrow" 
        :rightArrow="rArrow"
    />
    <FooterComponent :stickyFooter="true" />
</template>

<script>
export default {
    name: "AboutPage",
    components: {
        TopNav,
        BottomNav,
        FooterComponent,
    },
    created() {
        window.addEventListener('scroll', this.fadeBottomNav)
    },
    mounted() {
        this.applyNavClasses();
    },
    unmounted() {
        window.removeEventListener('scroll', this.fadeBottomNav)
    },
    data() {
        return {
            rArrow: {
                active: true,
                displayName: "my services",
                routerName: "/services"
            },
            lArrow: {
                active: true,
                displayName: "home",
                routerName: "/"
            }
        }
    },
    methods: {
        applyNavClasses() {
            document.body.classList.add("light");
            document.querySelector("#navbarmenu > a:nth-child(2) > div:nth-child(1)").classList.add("active-nav");
        },
        fadeBottomNav() {
            if (window.scrollY > 10) {
                document.querySelector('.bottom-nav-animated').classList.remove('fade-in');
                document.querySelector('.bottom-nav-animated').classList.add('fade-out');
            } else {
                document.querySelector('.bottom-nav-animated').classList.add('fade-in');
                document.querySelector('.bottom-nav-animated').classList.remove('fade-out');
            }
        }
    }

};

</script>

<style lang="scss">
.element-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.header {
    line-height: 4rem;
    font-size: 3rem;
}

//   about intro
.about-intro {
    background-color: var(--rubber-duckywhite);
    display: flex;
    flex-direction: row;
    padding-top: 3.5rem;
    height: calc(100vh - 5rem);

    .intro-text .text-container {
        font-size: 1.5rem;
        text-align: left;
        position: relative;
        left: 25%;
        top: 20%;
        width: 50%;

        p {
            margin: 1.5rem 0;
        }

        .intro-description {
            width: 100%;
            text-align: left;
        }
    }

    img {
        border-radius: 10px;
        width: min-content;
        height: min-content;
        max-width: 100%;
        max-height: 100%;
    }
}

.about-intro::after {
    position: absolute;
    content: '';
    left: 50%;
    top: 90%;
    width: 1px;
    height: 100vh * 2.5;
    background-color: var(--rubber-duckyyellow);
    mix-blend-mode: multiply;
}

// about tools

.about-tools {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .tools-container {
        position: relative;
        flex: 1;

        .text-container {
            position: relative;
            z-index: 1;
            left: 45%;
        }

        .tools-description {
            width: 42.25rem;
            font-size: 1.5rem;
        }

        .symbol {
            position: absolute;
            top: 0;
            left: 190%;
            z-index: 1;
            font-style: italic;
            color: var(--rubber-duckyyellow);
            font-size: 18.75rem;
        }
    }
}

.about-tools::after {
    content: "";
    display: inline-block;
    position: relative;
    transform: translateX(-25%);
    width: 70%;
    height: 75%;
    background-color: var(--rubber-duckywhite);
    border-radius: 10px;
    z-index: 0;
    box-shadow: var(--default-dropshadow);
}

// about skills

.about-skillset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40rem;
    height: 90vh;

    .skill-title-frame {
        display: inline-flex;
        align-items: center;
        gap: 3rem;
        opacity: 0.25;

        .skill-title {
            align-self: stretch;
            font-style: italic;
            color: var(--rubber-duckyblack);
            font-size: 11.25rem;
            width: 10rem;
        }
    }

    .skills {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 60px;

        .skill {

            display: flex;
            align-items: flex-start;
            gap: 1.875rem;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;

            .icon {
                color: var(--rubber-duckyorange);
                font-size: 3rem;
                text-align: right;
                line-height: 4rem;
                white-space: nowrap;

                &.icon-expand {
                    letter-spacing: 0.7rem;
                }
            }

            .skill-text {
                font-size: 3rem;
                line-height: 4rem;
            }
        }
    }
}

// about the ducky

.about-rubberducky {
    background-color: var(--rubber-duckywhite);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 90vh;
    z-index: 1;


    .content-block-logo {
        display: inline-flex;
        align-items: center;
        gap: 1.875rem;
        flex: 0 0 auto;

        img {
            width: 80%;
            height: 60%;
        }

        .ducky-content {
            width: 100%;
        }

        .ducky-description {
            width: 65%;
            font-size: 1.5rem;
            align-self: stretch;
        }
    }
}

// about hobbies

.about-hobbies {
    background-color: var(--rubber-duckydark-gray);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    height: 90vh;
    flex: 0 0 auto;
    overflow: visible;

    .hobbies-title {
        position: relative;
        align-self: stretch;
        color: var(--rubber-duckywhite);
        font-size: 3rem;
        text-align: center;
        line-height: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        p {
            margin: 0;
        }

        .hobbies-description {
            font-size: 1.5rem;
            width: 35%;
            color: var(--rubber-duckywhite);
            line-height: normal;
        }
    }

    .hobbies-icons {
        display: flex;
        width: 80%;
        align-items: flex-start;
        gap: 1.875rem;
        flex: 0 0 auto;

        .icon-frame {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            flex-grow: 1;
            font-size: 1.5rem;

            >div {
                color: var(--rubber-duckywhite);
            }
        }

        img {
            width: 50%;
            height: 50%;
        }
    }
}

@media only screen and (min-device-width: 320px) and (orientation: portrait) {

    .text-container {
        left: auto !important;
        font-size: 1.25rem !important;

        .header {
            font-size: 2.25rem !important;
        }
    }

    .element-about {

        .about-intro {
            flex-direction: column-reverse;
            padding-top: 2rem;
            padding-bottom: 5rem;
            height: auto;

            .intro-text {
                .text-container {
                    margin: 0 1rem;
                    top: auto;
                    width: auto;
    
                }
            }
            img {
                height: auto;
                width: auto;
            }
        }

        .about-intro::after {
            left: 50%;
            top: 145%;
            height: 100vh * 1.15;
        }

        .about-tools {
            margin-top: 2.5rem;

            .symbol {
                display: none;
            }

            .tools-container {
                flex: auto;
                margin: 0 1rem;

                .text-container {
                    .header {
                        margin: 0;
                    }
                    .tools-description {
                        width: auto;
                        margin-top: 0;
                    }
                }

            }

        }

        .about-skillset {
            gap: 0;
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 1rem;
            margin-bottom: 4rem;

            .skill-title-frame {
                margin-bottom: 2rem;

                .skill-title {
                    font-size: 6.25rem;
                }
            }

            .skills {
                gap: 2rem;

                .skill {
                    gap: 1rem;

                    .icon,
                    .skill-text {
                        font-size: 2.25rem;
                        line-height: 3rem;
                    }
                }
            }

        }

        .about-rubberducky {
            height: auto;
            padding: 2rem 0;

            .content-block-logo {
                display: flex;
                flex-direction: column;
                padding: 2rem;

                img {
                    width: 102%;
                }

                .header {
                    width: 97%;
                    font-size: 2.5rem;
                }
                .ducky-description {
                    width: auto;
                }
            }
        }

        .about-tools::after {
            display: block;
            position: absolute;
            transform: translateX(-50%);
            width: 95%;
            height: 90%;
            left: 50%;
        }

        .about-hobbies {
            height: auto;
            padding-top: 2rem;

            .hobbies-title {
                font-size: 2.5rem;
                align-items: flex-start;
                text-align: left;
                gap: 0;
                padding: 0 2rem;

                .hobbies-description {
                    width: auto;
                }
            }

            .hobbies-icons {
                flex-direction: column;
                font-size: 2.5rem;
                width: auto;
                align-items: center;
                margin-bottom: 5rem;
            }
        }
    }
}
</style>